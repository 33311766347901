import React, {useState, useEffect} from "react";
import {Grid2 as Grid, Tabs, Tab} from "@mui/material";
import {useNavigate, Outlet} from "react-router-dom";

type AdminResultsTab = "reviews" | "rankings" | "reports" | "results" | "proxyResults";

export function AdminResults() {

    const navigate = useNavigate();
    const [adminResultsTab, setAdminResultsTab] = useState<AdminResultsTab>(() => {
            const startingPage = window.location.pathname.split("/");
            return (startingPage[7]) ? startingPage[7] as AdminResultsTab : "rankings";
        }
    );

    useEffect(() => {
        navigate(adminResultsTab, {replace: true});
    }, [adminResultsTab, navigate])

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setAdminResultsTab(newValue as AdminResultsTab);
    }

    return (
        <Grid container sx={{mt: -7}}>
            <Grid container size={{xs: 12}}>
                <Grid size={{xs: 12}} sx={{my: 3}}>
                    <Tabs
                        value={adminResultsTab}
                        onChange={handleTabChange}
                        centered
                        textColor="inherit"
                        allowScrollButtonsMobile
                    >
                        <Tab label="Rankings" value="rankings" />
                        <Tab label="Results" value="results" />
                        <Tab label="Reviews" value="reviews" />
                        <Tab label="Reports" value="reports" />
                        <Tab label="Proxy Results" value="proxyResults" />
                    </Tabs>
                </Grid>
                <Grid size={{xs: 12}}>
                    <Outlet />
                </Grid>
            </Grid>
        </Grid>
    )
}